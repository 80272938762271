//@ts-nocheck
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { EllipsisHamburger } from "icons/Ellipsis";
import styles from "styles/profilescreen.module.scss";
import { ProfileSettingButton } from "components/ProfileSettingButton";
import { ProfileIcon } from "components/TopNav/icons";
import styled from "styled-components";
import { ForwardIcon } from "icons/AccountIcons";
import {
  ConnectAccountIcon,
  LogoutIcon,
  ManagePlaylistIcon,
  SubscriptionIcon,
} from "icons/AccountIcons";
import { ScreenTitle } from "components/ScreenTitle";
import ProfileImage from "../assets/smallprofileImg.png";
import { useSendLogoutMutation } from "features/auth/authApiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { Layout } from "components/Layout";
import { BackIcon } from "icons/BackIcon";
import cookies from "js-cookie";

const AccountScreen = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const userr = useSelector(selectCurrentUser);

  // Add try-catch for safer JSON parsing
  const user = (() => {
    try {
      return userr !== null ? userr : JSON.parse(cookies.get("playa_user") || "{}");
    } catch (e) {
      return null;
    }
  })();

  // Get first letter safely with optional chaining and fallback
  const firstLetter = user?.username?.charAt(0)?.toUpperCase() || '';

  const [logout] = useSendLogoutMutation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    cookies.remove("playa_access_token");
    cookies.remove("playa_user");
    navigate("/login");
  };

  return (
      <Layout>
        <div className={styles.profilescreenWrapper}>
          <section>
            <AccountTitleContainer>
              <ScreenTitle title="Accounts" icon={<BackIcon />} />
            </AccountTitleContainer>

            <ProfileBannerContainer>
              <ProfileImageText>
                <p>{firstLetter}</p>
              </ProfileImageText>
              <UsernameContainer>
                <p>{user?.username || 'User'}</p>
                <AccountTypeContainer>
                  <h5>Account Type:</h5>
                  <h6>Free Playa </h6>
                </AccountTypeContainer>
              </UsernameContainer>
            </ProfileBannerContainer>

            <div className={styles.profileSettingsContainer}>
             <ProfileSettingButton
               icon={<ProfileIcon />}
               title="Profile"
               screen="/profile"
               user={location.state}
             />
             <ProfileSettingButton
               icon={<ConnectAccountIcon />}
               title="Connected accounts"
               screen="/connectaccounts"
               user={location.state}
             />
             <ProfileSettingButton
               icon={<ManagePlaylistIcon />}
               title="Manage playlist"
               screen="/manageplaylist"
               user={location.state}
             />
             <ProfileSettingButton
               icon={<SubscriptionIcon />}
               title="Subscription"
               screen="/subscription"
               user={location.state}
             />
 
             <StyledButton onClick={handleLogout}>
               <div>
                 <LogoutIcon /> Logout
               </div>
               <ForwardIcon />
             </StyledButton>
           </div>
          </section>
        </div>
      </Layout>
  );
};

export default AccountScreen;

const ProfileImageText = styled.div`
  width: 50px;
  height: 50px;
  background-color: #008135;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #FFF;
    font-size: 20px;
  }
`;

const ProfileBannerContainer = styled.div`
  display: flex;
  padding-left: 1rem;
  margin-top: 3rem;
`;

const UsernameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  p {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
  }
`;

const AccountTypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  h6 {
    color: #434343;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;
const StyledButton = styled.button`
  display: flex;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  background: #fafafa;
  margin-bottom: 10px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const AccountTitleContainer = styled.div`
  display: flex;
  position: relative;
`;
