import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { OutputType } from "@types";
import {
  appleToApple,
  appleToSpotify,
  spotifyToApple,
  spotifyToSpotify,
  spotifyToYoutube,
  youtubeToApple,
  youtubeToSpotify,
} from "services/conversion";
import { PlaylistType } from "types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import { PlayaToast } from "utils";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { clearTotalTracks } from "features/tracks/tracksTotalSlice";
import { clearTracks } from "features/tracks/tracksSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import { MusicKitContext } from "components/MusicKitController";
import { useNavigate } from "react-router-dom";
import ProgressBar from "components/ProgressBar";
interface ModalProps {
  action: string;
  closeModal: () => void;
  playListArr: OutputType;
  onGoing: boolean;
  onGoingState: React.Dispatch<React.SetStateAction<boolean>>;
  actionCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  clickedItems: PlaylistType[];
  setClickedItems: React.Dispatch<React.SetStateAction<PlaylistType[]>>;
  source?: string;
  playlistName: string;
  progress?: any;
}

const OneTapCopyAndPlayModal: React.FC<ModalProps> = ({
  action,
  closeModal,
  playListArr,
  onGoing,
  playlistName,
  onGoingState,
  clickedItems,
  setClickedItems,
  actionCompleted,
  source,
  progress
}) => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const numOfCopiedTrack = useSelector(copied);
  const [conversionType, setConversionType] = useState("");
  const [isSelectPlatform, setIsSelectPlatform] = useState(false);
  const userr = useSelector(selectCurrentUser);
  const context = useContext(MusicKitContext);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
        dispatch(clearCopied());
        dispatch(clearFailedTracks());
        dispatch(clearTotalTracks());
        dispatch(clearTracks());
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setConversionType(user?.primaryStreamingService);
    copyPlaylist();
  }, [conversionType]);

  const copyPlaylist = async () => {
    const playlistData = clickedItems;
    onGoingState(true);

    if (source === "youtube" && conversionType === "spotify") {
      try {
        const resp = await youtubeToSpotify(
          playlistData,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          navigate(`/listeningroom/${resp?.playlistResults[0]?.newPlaylistId}`, { state: { playlistName } });
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }

    if (source === "spotify" && conversionType === "apple") {
      try {
        const resp = await spotifyToApple(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          navigate(`/listeningroom/${resp[0]?.ApplePlaylistId}`, { state: { playlistName: resp[0]?.playlistName } });
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    if (source === "apple" && conversionType === "spotify") {
      try {
        const resp = await appleToSpotify(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          actionCompleted(true);
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    //
    if (source === "youtube" && conversionType === "apple") {
      try {
        const resp = await youtubeToApple(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );

        if (resp) {
          onGoingState(false);
          navigate(`/listeningroom/${resp[0]?.ApplePlaylistId}`, { state: { playlistName: resp[0]?.playlistName } });
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    // SPOTFIY TO YOUTUBE
    if (source === "spotify" && conversionType === "youtube") {
      onGoingState(true);
   
      try {
        const resp = await spotifyToYoutube(
          playlistData,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          actionCompleted(true);
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
          actionCompleted(true);
      }
    }
    // APPLE TO YOUTUBE
    if (source === "apple" && conversionType === "youtube") {
      onGoingState(false);
      PlayaToast.error({
        msg: "Oops! coming soon",
      });
      // try {
      //   const resp = await spotifyToYoutube(
      //     playlistData,
      //     dispatch,
      //     numOfCopiedTrack
      //   );
      //   if (resp) {
      //     onGoingState(false);
      //     actionCompleted(true);
      //   }
      // } catch (error) {
      //   console.log(error, "<----- error");
      //   onGoingState(false);
      //     actionCompleted(true);
      // }
    }

    if (source === "spotify" && conversionType === "spotify") {
      try {
        const resp = await spotifyToSpotify(
          playlistData,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          navigate(`/listeningroom/${resp?.playlistResults[0]?.newPlaylistId}`, { state: { playlistName } });
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    if (source === "apple" && conversionType === "apple") {
      try {
        const resp = await appleToApple(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          navigate(`/listeningroom/${resp[0]?.ApplePlaylistId}`, { state: { playlistName } });
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
  };

  return (
    // <BottomModalContainer ref={ref}>
    //   <ButtomModalAction>
    //     <p>🎵 Give us a second to cook up your playlist</p>
    //     <ProgressBar progressPercentage={50} onProgressChange={closeModal} tintColor="#008135" />
    //   </ButtomModalAction>
    // </BottomModalContainer>

    <BottomModalContainer>
      <BottomModalContainerContent>
        <p>🎵 Give us a second to cook up your playlist</p>
        <ProgressBar progressPercentage={progress} onProgressChange={closeModal} tintColor="#008135" />
      </BottomModalContainerContent>
    </BottomModalContainer>
  );
};

export default OneTapCopyAndPlayModal;

const BottomModalContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  bottom: 10%;
  right: 0;
  left: 0;
  border-radius: 27px;
  z-index: 1000;
`;


const BottomModalContainerContent = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;

  p {
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    color: #262626;
    margin-bottom: 32px;
    margin-top: 8px;
  }
`;

const BottomModalCancelAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #5f7f90;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

