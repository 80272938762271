import React from "react";

export const AttentionIcon = () => {
  return (
    // <svg
    //   width="40"
    //   height="40"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <circle
    //     cx="12"
    //     cy="12"
    //     r="10"
    //     stroke="#5A94DE"
    //     stroke-width="2"
    //     fill="#e2efff"
    //   />
    //   <line x1="12" y1="8" x2="12" y2="16" stroke="#5A94DE" stroke-width="2" />
    //   <circle cx="12" cy="6" r="1.5" fill="#5A94DE" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      //   class="lucide lucide-info"
    >
      <circle cx="12" cy="12" r="10" stroke="#5A94DE" fill="#e2efff" />
      <path d="M12 16v-4" stroke="#5A94DE" />
      <path d="M12 8h.01" stroke="#5A94DE" />
    </svg>
  );
};
