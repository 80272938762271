import React from "react";
import styled from "styled-components";
import { HomeIcon, RoomIcon, ProfileIcon } from "icons";
import { useLocation, useNavigate } from "react-router-dom";

export const BottomNavigationTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <NavWrapper>
      <section>
        <NavIconContainer
          onClick={() => navigate("/home")}
          active={isActive("/home")}
        >
          <HomeIcon />
          <h5>Library</h5>
        </NavIconContainer>
        <NavIconContainer
          onClick={() => navigate("/ai-playlists")}
          active={isActive("/ai-playlists")}
        >
          <RoomIcon />
          <h5>Listen</h5>
        </NavIconContainer>
        <NavIconContainer
          onClick={() => navigate("/search")}
          active={isActive("/search")}
        >
          <ProfileIcon />
          <h5>Search</h5>
        </NavIconContainer>
      </section>
    </NavWrapper>
  );
};

const NavWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;

  section {
    padding: 10px 48px 10px 48px;
    border-radius: 20px;
    background: #000718;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const NavIconContainer = styled.button.attrs<{ active: boolean }>((props) => ({
  active: props.active,
}))`
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  path {
    stroke: ${({ active }) => (active ? "#3EF9AD" : "#8C8C8C")};
    fill: ${({ active }) => (active ? "#3EF9AD" : "#8C8C8C")};
  }

  h5 {
    color: ${({ active }) => (active ? "#3EF9AD" : "#8C8C8C")};
    font-size: 10px;
    font-weight: 700;
    margin-top: 5px;
  }
`;
